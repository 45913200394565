<div class="page-container">
  <div class="top-section" *ngIf="theme">
    <div class="left-container">
      <div class="register-logo-container">
        <img
          class="register-logo"
          src="{{ logoSrc }}"
          alt="Logo rejestracji"
          (error)="onLogoSrcError()"
        />
      </div>
    </div>
    <div class="right-container">
      <span class="have-account">Jeżeli nie wyrażasz wymaganych zgód</span>
      <button class="login-button" (click)="logout()">
        Wyloguj się
      </button>
    </div>
  </div>
  <div class="form-container" [ngStyle]="{ background: backgroundSrc }">
    <form novalidate [formGroup]="registerForm" *ngIf="showForm">
      <h1 class="form-header">
        Uzupełnij swoje dane i potwierdź zgody
      </h1>
      <div class="field-container" *ngIf="occupationalGroups?.length">
        <span class="p-float-label" ngbDropdown #dropdown="ngbDropdown">
          <i class="material-icons normal">arrow_drop_down</i>
          <input
            id="group"
            class="dropdown"
            formControlName="group"
            (click)="dropdown.open()"
            [ngClass]="{ error: hasErrors(group), dirty: group.value }"
            [value]="group.value.name"
            tabindex="-1"
            readonly
          />
          <label
            for="group"
            [ngClass]="{ dirty: group.value, error: hasErrors(group) }"
            >Kim jesteś?</label
          >
          <div ngbDropdownMenu aria-labelledby="dropdownManual">
            <button
              ngbDropdownItem
              class="dropdown-item"
              *ngFor="let option of occupationalGroups"
              (click)="setGroupValue(option)"
            >
              {{ option.name }}
            </button>
          </div>
        </span>
        <div class="control-errors" *ngIf="hasErrors(group)">
          <p *ngIf="group.errors.required">Pole jest wymagane</p>
        </div>
      </div>
      <div class="field-container" [hidden]="!group.value.codeValidator">
        <span class="p-float-label">
          <input
            id="pwzNumber"
            type="text"
            formControlName="pwzNumber"
            pInputText
            [ngClass]="{ error: hasErrors(pwzNumber), dirty: pwzNumber.dirty }"
          />
          <label
            for="pwzNumber"
            [ngClass]="{ dirty: pwzNumber.dirty, error: hasErrors(pwzNumber) }"
            >Twój numer PWZ</label
          >
        </span>
        <p *ngIf="!hasErrors(pwzNumber)" class="info">
          PWZ czyli prawo do wykonywania zawodu
        </p>
        <div class="control-errors" *ngIf="hasErrors(pwzNumber)">
          <p *ngIf="pwzNumber.errors.required">Pole jest wymagane</p>
          <p *ngIf="pwzNumber.errors.wrongPwzCode">Nieprawidłowy kod PWZ</p>
        </div>
      </div>
      <div class="field-container" *ngIf="areasOfInterest?.length">
        <span class="p-float-label" ngbDropdown #dropdown="ngbDropdown">
          <i class="material-icons normal">arrow_drop_down</i>
          <input
            id="area-of-interest"
            class="dropdown"
            formControlName="areaOfInterest"
            (click)="dropdown.open()"
            [ngClass]="{
              error: hasErrors(areaOfInterest),
              dirty: areaOfInterest.value
            }"
            [value]="areaOfInterest.value.name"
            tabindex="-1"
            readonly
          />
          <label
            for="area-of-interest"
            [ngClass]="{
              dirty: areaOfInterest.value,
              error: hasErrors(areaOfInterest)
            }"
            >Jaka dziedzina Cię interesuje?</label
          >
          <div ngbDropdownMenu aria-labelledby="dropdownManual">
            <button
              ngbDropdownItem
              class="dropdown-item"
              *ngFor="let option of areasOfInterest"
              (click)="setAreaOfInterestValue(option)"
            >
              {{ option.name }}
            </button>
          </div>
        </span>
        <div class="control-errors" *ngIf="hasErrors(areaOfInterest)">
          <p *ngIf="areaOfInterest.errors.required">Pole jest wymagane</p>
        </div>
      </div>
      <div class="field-container">
        <span class="p-float-label">
          <input
            id="firstName"
            type="text"
            formControlName="firstName"
            pInputText
            [ngClass]="{ dirty: firstName.dirty }"
          />
          <label for="firstName" [ngClass]="{ dirty: firstName.dirty }"
            >Twoje imię (opcjonalnie)</label
          >
        </span>
      </div>
      <div class="field-container">
        <span class="p-float-label">
          <input
            id="lastName"
            type="text"
            formControlName="lastName"
            pInputText
            [ngClass]="{ dirty: firstName.dirty }"
          />
          <label for="lastName" [ngClass]="{ dirty: lastName.dirty }"
            >Twoje nazwisko (opcjonalnie)</label
          >
        </span>
      </div>
      <div class="field-container">
        <span class="p-float-label">
          <input
            id="typeahead-country"
            type="text"
            [ngClass]="{ dirty: country.value, error: hasErrors(country) }"
            [formControl]="country"
            [ngbTypeahead]="countrySearch"
            [inputFormatter]="countryFormatter"
            [resultFormatter]="countryFormatter"
            [editable]="false"
          />

          <label
            for="typeahead-country"
            [ngClass]="{ dirty: country.value, error: hasErrors(country) }"
            >Kraj:</label
          >
        </span>
        <div class="control-errors" *ngIf="hasErrors(country)">
          <p *ngIf="country.errors.required">Pole jest wymagane</p>
        </div>
      </div>

      <div class="terms" *ngFor="let agreement of mandatoryAgreements">
        <input
          [id]="'agreement-' + agreement.code"
          class="terms-checkbox"
          type="checkbox"
          [value]="agreement.control.value"
          [formControlName]="'agreement-' + agreement.code"
          [ngClass]="{
            error: hasErrors(agreement.control),
            'internal-agreement': agreement.hidden
          }"
          required
        />
        <div *ngIf="!agreement.hidden" class="terms-controls">
          <label [for]="'agreement-' + agreement.code">
            <span style="color: red;">*</span> {{ agreement.text }}
          </label>
          <i
            class="far fa-question-circle cursor-pointer"
            (click)="
              displayToast('agreement-' + agreement.code, agreement.fullText)
            "
          ></i>
        </div>
        <div class="control-errors" *ngIf="hasErrors(agreement.control)">
          <p *ngIf="agreement.control.errors.required">Pole jest wymagane</p>
        </div>
      </div>
      <div class="terms" *ngFor="let agreement of optionalAgreements">
        <input
          [id]="'agreement-' + agreement.code"
          class="terms-checkbox"
          type="checkbox"
          [value]="agreement.control.value"
          [formControlName]="'agreement-' + agreement.code"
        />
        <div class="terms-controls">
          <label [for]="'agreement-' + agreement.code">
            {{ agreement.text }}
          </label>
          <i
            class="far fa-question-circle cursor-pointer"
            (click)="
              displayToast('agreement-' + agreement.code, agreement.fullText)
            "
          ></i>
        </div>
      </div>
      <!-- <div *ngIf="authConfig && authConfig.additionalAgreementTextRegistration" class="additional-agreement">
        <div class="innerHTML" [innerHTML]="authConfig.additionalAgreementTextRegistration"></div>
      </div> -->
      <div class="additional-agreement">
        <div class="innerHTML">
          <p>
            Zakładając konto akceptuję treść
            <strong>Regulaminu</strong> korzystania z serwisu oraz
            <strong>Polityki Prywatności</strong> (link do dokumentów na dole
            strony).
          </p>
          <p>
            Administratorem danych osobowych podanych w celu założenia konta
            jest
            <strong>Wydawnictwo Naukowe PWN S.A. </strong>z siedzibą w Warszawie
            (02-460), ul. G. Daimlera 2. Dane mogą być udostępniane na rzecz
            ePWN sp. z o.o. w celu ułatwienia Użytkownikowi założenia konta w
            serwisie tych spółek.
            <strong
              >Więcej informacji o zasadach przetwarzania danych, w tym
              przysługujących prawach, znajdziesz w </strong
            ><strong><u>Polityce prywatności</u></strong
            >.
          </p>
        </div>
      </div>
      <div class="button-container">
        <button
          class="submit-button"
          [ngClass]="{
            'submit-button-disabled': creatingAccount || registerForm.invalid
          }"
          type="submit"
          (click)="submitForm(modal)"
        >
          Aktualizuj dane
        </button>
      </div>
    </form>
  </div>
  <div class="bottom-section">
    <div class="right-container">
      <span *ngFor="let link of links">
        <a
          *ngIf="link.externalLink"
          href="{{ link.externalLink }}"
          target="_blank"
          rel="noopener noreferrer nofollow"
          >{{ link.displayName }}</a
        >
        <a *ngIf="link.internalLink" [routerLink]="link.internalLink">{{
          link.displayName
        }}</a>
        <a *ngIf="!link.externalLink && !link.internalLink">
          <app-modal-popups
            [displayName]="link.displayName"
            [pageContent]="link.page?.content"
          ></app-modal-popups>
        </a>
      </span>
    </div>
  </div>
</div>

<ng-template #modal let-modal>
  <div class="register-modal-window">
    <div class="top">
      <span class="title">Psychologia</span>
      <span class="close-button material-icons" (click)="modal.close()"
        >close</span
      >
    </div>
  </div>
  <div class="register-modal-window-middle">
    <div class="content-container">
      <h1 [innerHTML]="modalMessage"></h1>
      <button *ngIf="modalError" (click)="modal.close()">OK</button>
      <button *ngIf="!modalError" [routerLink]="['/']" (click)="modal.close()">
        OK
      </button>
    </div>
  </div>
</ng-template>

<ng-template #agreementModal let-agreementModal>
  <div class="register-modal-window">
    <div class="top-agreement">
      <span class="title">Psychologia</span>
      <span class="close-button material-icons" (click)="agreementModal.close()"
        >close</span
      >
    </div>
    <div class="register-modal-window-middle">
      <div class="content-container-agreement">
        <h1>{{ modalMessage }}</h1>
      </div>
    </div>
  </div>
</ng-template>
