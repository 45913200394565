import { env } from './.env';

export const environment = {
  production: true,
  hmr: true,
  version: env.npm_package_version,
  // serverUrl: 'http://pwn-dev-nginx:8083',
  CAPTCHA_KEY: '0x4AAAAAAA_y884LUIvmiFjB',
  serverUrl: 'https://psychologia.pwn.pl',
  defaultLanguage: 'pl-PL',
  supportedLanguages: ['pl-PL'],
  CHROME_BIN: '/usr/bin/chromium',
  tenantId: 'hubpsychologiczny',
  provider: 'local',
};
